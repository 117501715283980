@import '../../../../scss/base/var';

.container {
  display: flex;
  gap: 2px;
  width: 100%;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
}

.column {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 0;
}

.list {
  border: 1px solid #ccc;
  border-radius: 4px;
}

.columnHeader {
  font-weight: bold;
  padding-bottom: 4px;
}

.itemsList {
  min-height: 200px;
  padding: 4px;
  overflow-y: auto;
  flex: 1;
}

.draggable {
  padding: 4px;
  border-radius: 4px;
  // See https://stackoverflow.com/a/67075990/6235550
  top: auto !important;
  left: auto !important;
  transition: background-color ease-in-out 0.1s;
  margin-bottom: 3px;
  overflow-wrap: break-word;

  &:hover {
    background-color: $color-light-grey;
  }

  &.isDragging {
    background-color: #e0e0ff;
    border-color: #aaa;
  }

  &.isSelected {
    background-color: #e6f7ff;
    border-color: #1890ff;
  }
}

.controls {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
}

.controlButton {
  width: 30px;
  height: 20px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #e0e0e0;
  }

  &:active {
    background-color: #d0d0d0;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
}