@import '../../../../../scss/base/var';

.tabRadioContainer {
  display: inline-flex;
  gap: 2px;
  justify-content: space-between;
  border-radius: 18px;
  overflow-x: auto;
  overflow-y: hidden;
  border: 1px solid #ccc;
  background-color: #fff;
  width: 100%;
}

.tabOption {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 4px 8px;
  cursor: pointer;
  border: none;
  border-radius: 13px;
  background: transparent;
  font-size: 14px;
  font-weight: 400;
  transition: all 0.25s ease;
  color: #333;
  flex: 1;
  white-space: nowrap;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: $color-light-grey;
  }

  &.selected {
    background-color: $color-primary;
    color: white;
  }
}

// Hidden radio inputs
.radioInput {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}